import { v2Links } from '@utils/navigation/links';
import { Carousel } from '@v2/components';
import { getPopularArtists } from '@v2/services/getTopSellingEvents';
import Image from 'next/image';
import Link from 'next/link';

export const TopArtists = async () => {
  const topArtists = await getPopularArtists({
    withVenueImages: true,
    perPage: 50,
  });

  return (
    <div>
      <h1 className="mb-6 text-lg font-bold md:text-2xl">Top Artists</h1>
      <Carousel>
        {topArtists.map(({ ...rest }) => {
          const { id, name, url } = rest;
          if (!url) return null;

          const randomNumber = Math.floor(Math.random() * 11) + 1;
          const imagePath = `/concerts/rsc-${randomNumber}.jpg`;
          return (
            <Link
              href={`${v2Links.buy}?eventId=${id}`}
              key={id}
              className="flex max-h-[214px] flex-shrink-0 flex-grow-0 basis-60 flex-col"
            >
              <div className="mb-3 h-40 w-full flex-shrink-0 overflow-hidden rounded-lg">
                <Image
                  src={imagePath}
                  alt={name}
                  width="0"
                  height="0"
                  sizes="246px"
                  loading="lazy"
                  className="h-[160px] w-[246px] rounded-lg object-cover object-center"
                />
              </div>
              <div className="w-full max-w-64">
                <h2 className="mb-2 text-sm font-semibold md:text-base">
                  {name}
                </h2>
              </div>
            </Link>
          );
        })}
      </Carousel>
    </div>
  );
};
